<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="订单管理" />
		<SearchForm>
			<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
				<el-form-item prop="realName">
					<el-input v-model.trim="searchForm.mfDockorId" placeholder="请输入客户Id" />
				</el-form-item>
				<el-form-item prop="mchId">
					<!-- <el-input v-model.trim="searchForm.mchId" placeholder="请输入所属商户" /> -->
					<el-select v-model="searchForm.mchId" placeholder="请选择所属商户" @change="changeShop1" filterable
						clearable>
						<el-option v-for="item in options1" :key="item.merchantId" :label="item.merchantName"
							:value="item.merchantId" />
					</el-select>
					<!-- <el-cascader v-model="searchForm.areaIds" style="width: 350px;" :options="orgAreaTree"
						placeholder="选择订单名" clearable filterable collapse-tags
						:props="{ multiple: true, checkStrictly: false, emitPath: false, label: 'orgName', value: 'orgId' }" /> -->
				</el-form-item>
				<el-form-item prop="shopName">
					<el-select v-model="searchForm.shopName" placeholder="请选择店铺名称" @change="changeShop" filterable
						clearable>
						<el-option v-for="item in options" :key="item.dicCode" :label="item.dicName"
							:value="item.dicCode" />
					</el-select>
				</el-form-item>
				<el-form-item prop="receiverMobile">
					<SearchForm>
						<div class="popperClass">
							<el-date-picker v-model.trim="searchForm.daterange" popper-class="popperClass"
								:picker-options="pickerOptions" type="daterange" range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch" />
						</div>
					</SearchForm>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="fetchData">搜 索</el-button>
					<!-- 导出按钮 -->
					<div style="margin-left: 100px;margin-top: -40px;">
						<download-excel class="export-excel-wrapper" :data="json_data" name="订单.xlsx">
							<el-button @click="exportAll" type="primary">双击导出</el-button>
						</download-excel>
					</div>



				</el-form-item>
			</el-form>
		</SearchForm>
		<TableContainer title="订单列表">
			<!-- <template slot="button">
				<el-button type="success" @click="addGarden">添加订单</el-button>
			</template> -->
			<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
				:cell-style="{ textAlign: 'center' }">
				<!-- <el-table-column prop="id" label="id" min-width="160" /> -->
				<el-table-column prop="mchName" label="所属商户" min-width="100" />
				<el-table-column prop="shopName" label="店铺名" min-width="100" />
				<el-table-column prop="mfDockorId" label="客户id" min-width="100" />
				<!-- <el-table-column prop="mchId" label="所属商户" min-width="100" /> -->
				<!-- <el-table-column prop="tradeNo" label="订单编号" min-width="100" /> -->
				<el-table-column prop="customerName" label="用户名称" min-width="100" />
				<!-- <el-table-column prop="shopName" label="店铺名" min-width="100" /> -->
				<el-table-column prop="customerId" label="买家ID" min-width="100" />
				<!-- <el-table-column prop="customerNo" label="客户编码" min-width="100" /> -->
				<el-table-column prop="receiverAddress" label="地址" min-width="100" />
				<el-table-column prop="receiverMobile" label="手机" min-width="100" />
				<el-table-column prop="paid" label="已付金额" min-width="100" />
				<el-table-column prop="created" label="订单日期" min-width="120" />
				<el-table-column prop="modifyTime" label="匹配日期" min-width="100" />
				<el-table-column prop="buyStatus" label="购买状态" min-width="100" />
				<!-- <el-table-column prop="principalName" label="下单数" min-width="100" />
				<el-table-column prop="phone" label="支付数" min-width="100" />
				<el-table-column prop="remark" label="备注" min-width="100" show-overflow-tooltip /> -->
				<el-table-column width="120" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden2(scope.row)">商品详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>

		<GDialog :dialog.sync="gardenDialog1" @btnSure="submitThemeForm()" @btnClose="resetGardenForm()">
			<!-- <SearchForm>
				<el-date-picker v-model="daterange" :picker-options="pickerOptions" type="daterange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch" />
					<el-button type="primary" @click="fetchData2">搜 索</el-button>
			</SearchForm> -->
			<!-- <el-table :data="tableData2" stripe style="width: 100%">
				<el-table-column prop="realName" label="用户名" min-width="100" />
				<el-table-column prop="tradeNo" label="订单编号" min-width="100" />
				<el-table-column prop="customerName" label="客户名称" min-width="100" />
				<el-table-column prop="customerId" label="买家ID" min-width="100" />
				<el-table-column prop="customerNo" label="客户编码" min-width="100" />
				<el-table-column prop="receiverAddress" label="地址" min-width="100" />
				<el-table-column prop="receiverMobile" label="手机" min-width="100" />
				<el-table-column prop="paid" label="已付金额" min-width="100" />
				<el-table-column width="120" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden2(scope.row)">商品详情</el-button>
					</template>
				</el-table-column>
			</el-table> -->
		</GDialog>
		<GDialog :dialog.sync="gardenDialog2" @btnSure="submitThemeForm()" @btnClose="resetGardenForm()">
			<el-table :data="tableData3" stripe style="width: 100%">
				<!-- <el-table-column prop="id" label="id" min-width="160" /> -->
				<el-table-column prop="goodsName" label="货品名称" min-width="100" />
				<el-table-column prop="num" label="下单数量" min-width="100" />
				<el-table-column prop="price" label="标价" min-width="100" />
				<el-table-column prop="refundStatus" label="退款状态" min-width="100" />
				<el-table-column prop="actualNum" label="实发数量" min-width="100" />
				<el-table-column prop="orderPrice" label="成交价" min-width="100" />
				<el-table-column prop="paid" label="已付" min-width="100" />

			</el-table>
		</GDialog>
	</section>

</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		postFindMfDockor,
		findMfDockorUser,
		findMfUserTradeByUserId,
		selectAllTrade,
		findTradeGoods,
		getDictoryInfoByCode,
		exportClassList
	} from '@api/gardenManagement/api_gardenManagement'
	import { findMfAgent } from '@api/userMangement/api_userMangement'
	export default {
		data() {
			return {
				currentRow: {},
				daterange: "",
				pickerOptions: {
					onPick({
						maxDate,
						minDate
					}) {
						// 当开始时间结束时间都选择了之后才执行搜索
						if (maxDate && minDate) {
							this.$nextTick(() => {
								this.handleSearch()
							})
						}
					}
				},
				beginTime: "",
				endTime: "",
				gardenDialog: {
					title: '用户信息',
					visible: false
				},
				gardenDialog1: {
					title: '用户订单信息',
					visible: false
				},
				gardenDialog2: {
					title: '商品信息',
					visible: false
				},
				options: [],
				options1: [],
				tableData: [],
				tableData1: [],
				tableData2: [],
				tableData3: [],
				tableData4: [],
				json_data: [],
				total: 0,
				searchForm: {
					// merchantId: 0,
					mchId: "",
					beginTime: "",
					endTime: "",
					mobilePhone: "",
					shopName: "",
					mfDockorId: "",
					page: 1,
					size: 20,
					startNum: 1
				},
				searchForm1: {
					// merchantId: 1,
					// keyWord: "",
					mobilePhone: "",
					realName: "",
					page: 1,
					size: 20,
					startNum: 1
				},
				gardenForm: {
					areaId: '',
					orgName: '',
					principalName: '',
					phone: '',
					remark: ''
				},
				// rules: {
				// 	areaId: [{
				// 		required: true,
				// 		message: '请选择归属',
				// 		trigger: 'change'
				// 	}],
				// 	orgName: [{
				// 		required: true,
				// 		message: '请输入园所名称',
				// 		trigger: 'blur'
				// 	}],
				// 	principalName: [{
				// 		required: true,
				// 		message: '请输入负责人',
				// 		trigger: 'blur'
				// 	}],
				// 	phone: [{
				// 			required: true,
				// 			message: '请输入手机号',
				// 			trigger: 'blur'
				// 		},
				// 		{
				// 			pattern: /^1[3456789]\d{9}$/,
				// 			message: '手机号格式错误',
				// 			trigger: 'blur'
				// 		}
				// 	]
				// }
			}
		},
		computed: {
			...mapState('commonModule', ['orgAreaTree'])
		},
		created() {
			this.getOptions()
			this.fetchData()
			this.getDictoryInfoByCode()
		},
		watch: {
			allData(val, oldV) {
				console.log(val.length, oldV.length)
				if (val.length > 0) {
					setTimeout(() => {
						this.$refs.downloadBtn.$el.click()
					}, 10) //监听导出数据，判断满足条件等一下下触发导出点击事件
				}
			}
		},

		methods: {
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			getOptions() {
				// 查询组织机构树-到片区；2到片区，3到园所
				this.$store.dispatch('commonModule/getOrgTree', 2)
			},
			getDictoryInfoByCode() {
				getDictoryInfoByCode().then(res => {
					this.options = res
				})
				
				findMfAgent(this.searchForm).then(res =>{
					this.options1 = res.records
				})
			},
			changeShop(val) {
				this.searchForm.dicCode = val
			},
			changeShop1(val) {
				console.log(val)
				this.searchForm.mchId = val
			},
			formatDate(date, fmt) {
				if (/(y+)/.test(fmt)) {
					fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
				}
				let o = {
					'M+': date.getMonth() + 1,
					'd+': date.getDate(),
					'h+': date.getHours(),
					'm+': date.getMinutes(),
					's+': date.getSeconds()
				}
				for (let k in o) {
					if (new RegExp(`(${k})`).test(fmt)) {
						let str = o[k] + ''
						fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
					}
				}
				return fmt
			},
			padLeftZero(str) {
				return ('00' + str).substr(str.length)
			},

			handleSearch(val) {
				// console.log(val,1111)
				// console.log(val[0],1111)
				let date = val[0];
				let date1 = val[1];
				this.searchForm.beginTime = this.formatDate(date, 'yyyy-MM-dd hh:mm:ss');
				this.searchForm.endTime = this.formatDate(date1, 'yyyy-MM-dd hh:mm:ss');
				// console.log(this.beginTime,1111)

			},
			fetchData() {
				this.searchForm.type = 3
				selectAllTrade(this.searchForm).then(res => {
					console.log(res)
					this.tableData = res.records
					this.total = res.total
				})
			},
			exportAll() {
				exportClassList(this.searchForm).then(res => {
					console.log(res)
					this.tableData4 = res;
					this.json_data = [];
					for (var i of this.tableData4) {
						
						this.json_data.push({
							"所属商户": i.mchName,
							"店铺名": i.shopName,	
							"客户id": i.mfDockorId,
							"用户名称": i.customerName,
							"买家ID": i.customerId,
							"地址": i.receiverAddress,
							"手机": i.receiverMobile,
							"已付金额": i.paid,
							"订单日期": i.created,
							"匹配日期": i.modifyTime,
							"购买状态": i.buyStatus,
							"商品名称": i.goodsName,
							"商品数量": i.num,
							"商品价格": i.price,
							"商品金额": i.goodsPaid
							



						})
					}

					// this.downloadCallback(res, '藏品交易记录.xlsx');

				}).catch(err => {

				})
			},
			downloadCallback(res, fileName) {
				console.log(res)
				const content = res.data;
				const blob = new Blob([content]);
				if ("download" in document.createElement("a")) {
					// 非IE下载
					const elink = document.createElement("a");
					elink.download = fileName;
					elink.style.display = "none";
					elink.href = URL.createObjectURL(blob);
					document.body.appendChild(elink);
					elink.click();
					URL.revokeObjectURL(elink.href); // 释放URL 对象
					document.body.removeChild(elink);
				} else {
					// IE10+下载
					navigator.msSaveBlob(blob, fileName);
				}
			},

			fetchData1() {
				this.searchForm.type = 3
				findMfDockorUser(this.searchForm1).then(res => {
					this.tableData1 = res.records
					this.total = res.total
				})
			},
			fetchData2() {
				let params = {
					startTime: this.beginTime,
					endTime: this.endTime,
					page: 1,
					size: 10

				}
				findMfUserTradeByUserId(params).then(res => {
					this.tableData2 = res.records
					this.total = res.total
				})
			},
			// addGarden() {
			// 	this.gardenDialog.visible = true
			// 	this.gardenDialog.title = '添加订单'
			// },
			editGarden(row) {
				// console.log(row,1111)
				this.gardenDialog.visible = true
				let params = {
					parentId: row.userId,
					page: 1,
					size: 10

				}
				findMfDockorUser(params).then(res => {
					this.tableData1 = res.records
					this.total = res.total
				})
			},
			editGarden1(row) {
				console.log(row, 1111)
				this.gardenDialog1.visible = true
				let params = {
					parentId: row.userId,
					page: 1,
					size: 10

				}
				findMfUserTradeByUserId(params).then(res => {
					this.tableData2 = res.records
					this.total = res.total
				})
			},
			editGarden2(row) {
				console.log(row, 1111)
				this.gardenDialog2.visible = true
				let params = {
					tradeId: row.tradeId,
					page: 1,
					size: 10

				}
				findTradeGoods(params).then(res => {
					console.log(res)
					this.tableData3 = res
					this.total = res.total
				})
			},
			submitThemeForm() {
				this.gardenDialog.visible = false
			},
			resetGardenForm(formName) {
				// this.$refs[formName].resetFields()
				this.gardenDialog.visible = false
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.customWidth {
		width: 50%;
	}

	.popperClass {
		.el-date-editor .el-range-separator {
			width: 20%;
		}
	}
	
	.reviewed {
		color: #38b9be;
	}
</style>
